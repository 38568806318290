import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Container } from "../layout/Container";
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "./SignInButton";
import { AppLogo } from "../misc/AppLogo";
import { isHmc } from "../../utils/isHmc";
import maskEmail from "../../utils/mask-email";
import { useCssBreakpoints } from "react-use-css-breakpoints";

export function HomePage() {
  const auth = useContext(AuthContext);
  const intl = useIntl();

  const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();

  const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);

  const breakpoint = useCssBreakpoints();
  const barHeight = breakpoint === "sm" ? 4 : 6;

  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true);
    }
  }, []);

  return (
    <PageContainer className={styles.homePage}>
      <Container>
        <div className="tw-w-full tw-grid tw-justify-items-center tw-gap-6 tw-mt-16 tw-px-4">
          <div className="tw-text-3xl md:tw-text-6xl tw-font-bold tw-relative tw-z-0">
            Meta Tabi Fukushima<span className="tw-absolute tw-left-full"></span>
          </div>
          <svg
            width="100%"
            height={barHeight}
            viewBox={`0 0 450 ${barHeight}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="450" height={barHeight} rx={barHeight / 2} fill="#00C8EA" />
          </svg>
          {/* <div className="tw-text-xs md:tw-text-lg tw-text-base-content/60 tw-font-bold">
            バーチャルイベントや3D展示会に。貴社のバーチャル空間を作成いたします。
          </div> */}
        </div>
      </Container>
      {/* {sortedPublicRooms.length > 0 && (
        <Container className={styles.roomsContainer}>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center>
              {sortedPublicRooms.map(room => {
                return (
                  <MediaTile
                    key={room.id}
                    entry={room}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                );
              })}
            </MediaGrid>
          </Column>
        </Container>
      )} */}
      {/* {sortedFavoriteRooms.length > 0 && (
        <Container className={styles.roomsContainer}>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center>
              {sortedFavoriteRooms.map(room => {
                return (
                  <MediaTile
                    key={room.id}
                    entry={room}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                );
              })}
            </MediaGrid>
          </Column>
        </Container>
      )} */}
    </PageContainer>
  );
}
