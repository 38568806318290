import React, { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Button.scss";
import textInputStyles from "./TextInput.scss";
import { FormattedMessage } from "react-intl";

/**
 * @typedef {(
 * "transparent" |
 * "basic" |
 * "primary" |
 * "accept" |
 * "cancel" |
 * "accent1" |
 * "accent2" |
 * "accent3" |
 * "accent4" |
 * "accent5" |
 * "accent6" |
 * "landing" |
 * "signin"
 * )} Preset
 */

/** @type {Preset[]} */
export const presets = [
  "transparent",
  "basic",
  "primary",
  "accept",
  "cancel",
  "accent1",
  "accent2",
  "accent3",
  "accent4",
  "accent5",
  "accent6",
  "landing",
  "signin",
  "text"
];

/* eslint-disable-next-line react/display-name */
export const Button = memo(
  forwardRef(
    /**
     * @param {Props} props
     */
    ({ as, preset, className, children, block, ...rest }, ref) => {
      const ButtonComponent = as;
      const buttonProps = ButtonComponent === "button" ? { type: "button" } : {};

      return (
        <ButtonComponent
          className={classNames(
            "tw-border-solid",
            "tw-btn",
            "md:tw-btn-lg",
            "tw-py-1",
            "tw-rounded-full",
            "tw-whitespace-nowrap",
            // NOTE: presetによってボタンの種類を変える
            {
              "tw-btn-ghost": preset === "transparent",
              [classNames("tw-btn-outline", "tw-bg-[#ffffff]")]: preset === "accent5" || preset === "cancel"
            },
            {
              "tw-btn-block": block
            },
            className
          )}
          {...buttonProps}
          {...rest}
          ref={ref}
        >
          {children}
        </ButtonComponent>
      );
    }
  )
);

Button.propTypes = {
  as: PropTypes.elementType,
  preset: PropTypes.oneOf(presets),
  className: PropTypes.string,
  children: PropTypes.node,
  sm: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  thin: PropTypes.bool,
  thick: PropTypes.bool
};

Button.defaultProps = {
  as: "button",
  preset: "basic"
};

export function NextButton(props) {
  return (
    <Button preset="accept" {...props}>
      <FormattedMessage id="button.next" defaultMessage="Next" />
    </Button>
  );
}

export function CancelButton(props) {
  return (
    <Button preset="cancel" {...props}>
      <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
    </Button>
  );
}

export function ContinueButton(props) {
  return (
    <Button preset="accept" {...props}>
      <FormattedMessage id="button.continue" defaultMessage="Continue" />
    </Button>
  );
}

export function AcceptButton(props) {
  return (
    <Button preset="accept" {...props}>
      <FormattedMessage id="button.accept" defaultMessage="Accept" />
    </Button>
  );
}

export function ApplyButton(props) {
  return (
    <Button preset="accept" {...props}>
      <FormattedMessage id="button.apply" defaultMessage="Apply" />
    </Button>
  );
}
